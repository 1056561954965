/* body {
    margin: 0;
    padding: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: #f6f7fa;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
} */

[data-amplify-authenticator] [data-amplify-container] {
  position: relative;
  z-index: 1;
  top: 50%;
  /* position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  z-index: 1; */
}

[data-amplify-authenticator] {
  --amplify-colors-font-interactive: #13c56b;
  --amplify-components-tabs-item-active-color: #13c56b;
  --amplify-components-tabs-item-focus-color: #13c56b;
  --amplify-components-tabs-item-hover-color: #13c56b;
  --amplify-components-tabs-item-active-border-color: #13c56b;
  --amplify-components-button-primary-background-color: #13c56b;
  --amplify-components-button-primary-hover-background-color: #05984f;
  --amplify-components-fieldcontrol-active-border-color: #13c56b !important;
  --amplify-components-button-link-color: var(
    --amplify-colors-font-interactive
  );
  --amplify-components-button-link-hover-color: #05984f;
}

.my_container {
  position: relative;
  margin: 0 auto;
  width: 90vw;
  min-width: 800px;
  margin-bottom: 40px;
}

#page_header {
  position: sticky;
  top: 0;
  z-index: 1;
  position: -webkit-sticky;
  width: 100%;
  /* height: 60px; */
  background-color: #fff;
}

#page_header > .my_container {
  display: flex;
}

.v-my-checkbox {
  font-size: 1rem;
  margin-right: 1rem;
}

.navbar-menu .navbar-nav .nav-link.active,
.navbar-menu .navbar-nav .nav-link:hover,
#header-menu .nav-link.active,
#header-menu .nav-link:hover {
  color: #865ce2 !important;
}

.navbar-menu .navbar-nav .link-success {
  color: unset !important;
}

.flatpickr-input {
  border: 1px solid #bbb;
  border-radius: 0.25rem;
  padding-left: 8px;
  padding-right: 8px;
  background-color: #fff;
  color: #333;
}

.flatpickr-months,
.flatpickr-weekdays {
  background-color: #865ce2 !important;
}

.flatpickr-day.selected {
  background-color: #865ce2 !important;
  border-color: #865ce2 !important;
}

.flatpickr-day.today {
  color: #865ce2 !important;
  background-color: transparent !important;
  border-color: transparent !important;
}

.echarts-for-react {
  width: 100%;
  height: 100% !important;
}

.logo-dark {
  color: #333 !important;
}

.maxw {
  max-width: 100px;
}

.new_price {
  color: #3de1ad;
}

.badPlanTitleDiv {
  display: flex;
  align-items: center;
}

.badPlanTitleDiv span {
  white-space: nowrap;
}
.jVeafb {
  font-size: 14px !important;
}
.css-1in441m {
  display: none !important;
}

.tooltip-content {
  z-index: 2000;
  max-width: 80%;
}

.tooltip-content p {
  margin-bottom: 0;
}

.tooltip-content a {
  color: #fff;
  text-decoration: underline !important;
}

.dropdown-btn:hover,
.topbar-user .btn:hover {
  border: 1px solid #ddd;
}

.combined-table {
  display: flex;
  overflow-x: auto;
}

.fronzen-table {
  position: sticky;
  left: 0;
  z-index: 1;
}

.scrollable-table {
  overflow-x: auto;
  flex: 1;
}

.sidebar-menu {
  width: 100%;
  /* z-index: 1002; */
  background: #38454a;
  border-right: 1px solid #38454a;
  bottom: 0;
  margin-top: 0;
  position: absolute;
  top: 0;
  -webkit-box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  box-shadow: 0 2px 4px rgba(15, 34, 58, 0.12);
  padding: 0 0 calc(70px + 25px) 0;
  -webkit-transition: all 0.1s ease-out;
  transition: all 0.1s ease-out;
}

.sidebar-menu .nav-link {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  padding: 0.625rem 1.5rem !important;
  color: #a1b7bf;
  font-size: 0.925rem;
}

.sidebar-menu .nav-link::after {
  display: block;
  content: "\f0142";
  font-family: "Material Design Icons";
  margin-left: auto;
  -webkit-transition: -webkit-transform 0.2s;
  transition: -webkit-transform 0.2s;
  transition: transform 0.2s;
  transition: transform 0.2s, -webkit-transform 0.2s;
  -webkit-transform: rotate(0deg);
  transform: rotate(0deg);
  font-size: 1.05rem;
  position: absolute;
  right: 18px !important;
  color: inherit;
}

/* Sidebar menu with horizontal layout */
/* [data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"] {
  color: #fff !important;
} */

[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"]::after {
  color: #fff !important;
}

[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"]:hover,
[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"]:focus {
  color: #fff !important;
}

/* Expanded state */
[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]::after {
  -webkit-transform: rotate(90deg) !important;
  transform: rotate(90deg) !important;
}

[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"],
[data-layout="horizontal"]
  .sidebar-menu
  .nav-item
  .nav-link[data-bs-toggle="collapse"][aria-expanded="true"]:hover::after {
  color: #fff !important;
}

/* Small nav padding and icon */
.sidebar-menu .nav-sm {
  padding-left: 1.75rem;
}

.sidebar-menu .nav-sm .nav-link::after {
  display: none;
}

.sidebar-menu .nav-sm .nav-link::before {
  content: "";
  width: 6px;
  height: 1.5px;
  background-color: #a1b7bf;
  position: absolute;
  left: 2px;
  top: 19px;
  -webkit-transition: all 0.4s ease-in-out;
  transition: all 0.4s ease-in-out;
  opacity: 0.5;
}

/* Active and hover state styles */
.sidebar-menu .nav-sm .nav-link.active,
.sidebar-menu .nav-sm .nav-link:focus,
.sidebar-menu .nav-sm .nav-link:hover {
  color: #fff !important;
}

.sidebar-menu .nav-sm .nav-link.active::before,
.sidebar-menu .nav-sm .nav-link:hover::before {
  opacity: 1;
}

.page-tabs {
  border-width: 1.5px !important;
  border-bottom-color: #ccc !important;
}

.page-tabs .nav-link {
  font-size: 16px;
}

.page-tabs .nav-link.active,
.page-tabs .nav-item.show .nav-link {
  border-width: 1.5px !important;
  border-color: #ccc #ccc transparent !important;
}

.page-tabs .nav-link:hover,
.page-tabs .nav-link:focus {
  color: inherit !important;
  border-color: #ddd #ddd transparent !important;
}

.page-tabs .nav-link.active {
  font-weight: 500;
}

.show-xs {
  display: none;
}

#geo-chart path {
  stroke: #B5B1C4;
}

#change-utility-state-btn {
  display: none;
}

@media screen and (min-width: 1024.1px) {
  .sidebar-menu {
    bottom: 1.5rem;
    padding: 0;
    border-radius: 5px;
    padding: 10px 0;
    z-index: 1;
  }
}

@media screen and (max-width: 768px) {
  .hide-xs {
    display: none;
  }

  .show-xs {
    display: block;
  }

  .dropdown-btn {
    font-size: 12px !important;
    line-height: 1.1 !important;
  }

  #login-btn {
    margin: -32px 0 0;
  }

  .logo.logo-dark {
    margin-top: 12px;
    margin-bottom: 4px;
  }

  .navbar-header {
    padding-bottom: 4px !important;
  }

  .nav-item {
    margin-bottom: 16px;
  }

  #header-menu .dropdown-btn {
    font-size: 1rem !important;
  }

  .col-md-10 .container {
    padding-left: 0;
    padding-right: 0;
  }

  .sidebar-menu {
    position: fixed;
    z-index: 99999;
    bottom: 0;
    left: 0;
    border-radius: 0;
    padding-bottom: 0;
    display: none;
  }

  .sidebar-menu #scrollbar {
    background-color: rgb(33, 37, 41);
    width: 300px;
  }

  .sidebar-menu {
    display: none;
    background-color: rgba(0, 0, 0, 0.1);
  }

  .sidebar-menu.active {
    display: block;
  }

  .sidebar-menu.active .sidebar-background {
    display: block;
  }

  #change-utility-state-btn {
    display: block;
    position: fixed;
    bottom: 4%;
    left: 50%;
    transform: translateX(-50%);
    background-color: #333;
    color: #fff;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
    padding: 10px 20px;
    border-radius: 30px;
    font-size: 13px;
    cursor: pointer;
    z-index: 999999;
    opacity: 0.9;
  }
}
