.price-summary {
    display: flex;
    justify-content: space-between;
    margin: 5px 0;
    padding-right: 2rem;
}

.login_marketTitle {
    font-size: 1.5rem;
    margin-bottom: 1rem;
    display: flex;
    justify-content: space-between;
    min-width: 20vw;
}

.plan_rank {
    /* height: 20rem; */
    width: 100%;
    /* background-color: #f9fef0; */
    /* padding: 5px; */
}

.plan_rank>p {
    color: black !important;
}

/* market trend */
#avg_price {
    height: 400px;
}

.my-loader {
    width: 5rem;
    height: 5rem;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

/* .highcharts-figure,
.highcharts-data-table table {
    min-width: 310px;
    max-width: 800px;
    margin: 1em auto;
}

.highcharts-data-table table {
    font-family: Verdana, sans-serif;
    border-collapse: collapse;
    border: 1px solid #ebebeb;
    margin: 10px auto;
    text-align: center;
    width: 100%;
    max-width: 500px;
}

.highcharts-data-table caption {
    padding: 1em 0;
    font-size: 1.2em;
    color: #555;
}

.highcharts-data-table th {
    font-weight: 600;
    padding: 0.5em;
}

.highcharts-data-table td,
.highcharts-data-table th,
.highcharts-data-table caption {
    padding: 0.5em;
}

.highcharts-data-table thead tr,
.highcharts-data-table tr:nth-child(even) {
    background: #f8f8f8;
}

.highcharts-data-table tr:hover {
    background: #f1f7ff;
} */

@media(max-width: 768px) {
    .pc-compareSelect{
        display: none;
    }
    .basedData-card .flex-shrink-0{
        margin-top: 8px;
    }
    .basedData-card .flex-shrink-0 span{
        font-size: 14px !important;
    }
    .counter-value{
        font-size: 16px !important;
    }
    .counter-value img{
        height: 60px !important;
    }
    .py-4{
        padding-top: 1rem !important;
        padding-bottom: 1rem !important;
    }
    .px-4{
        padding-left: 1rem !important;
        padding-right: 1rem !important;
    }
}
.tooltip-button:hover{
    background-color: unset !important;
}
@media (min-width:768px) {
    .mobile-compareSelect{
        display: none;
    }
    .basedData-card{
        display: flex;
        justify-content: center;
        align-items: center;
    }
}