.ai-summary-section {
  margin: 16px 20px 8px;
  border-radius: 8px;
  box-shadow: 0 4px 16px rgba(0, 0, 0, .03);
  border: 1px solid #ebebeb;
  background: #fafafa;
  background: linear-gradient(to bottom, #f9f9f9 0%, #fefefe 30%, #fbfbfb 100%);
  overflow: hidden;
}

.ai-summary-section h4 {
  color: #333;
  margin: 0;
  padding: 6px 6px 0;
  font-size: 16px;
}

.ai-summary-container {
  /* background-color: #fff; */
  overflow: hidden;
  /* padding: 12px; */
  border-radius: 4px;
}

.ai-summary-btn {
  background: linear-gradient(
    111.6deg,
    rgb(174, 68, 223) 27.3%,
    rgb(246, 135, 135) 112.7%
  );
  color: #fff;
  padding: 0.5rem 0.9rem;
  font-size: 0.925rem;
  border: 0 none;
  border-radius: 0.25rem;
  font-weight: 500;
}

.ai-summary-btn i {
  font-size: 0.75rem;
  font-style: normal;
  font-weight: 300;
}

.ai-summary-content {
  color: #444;
  padding: 12px 16px;
  border-top: 1px solid #efefef;
  border-bottom-left-radius: 6px;
  border-bottom-right-radius: 6px;
  background-color: #fff;
  font-size: 15px;
}

.ai-summary-content p {
  margin-bottom: 0;
}

.ai-summary-thinking {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  justify-content: center;
  line-height: 1.1;
}

.ai-summary-thinking span {
  animation: glow 1s ease-in-out infinite alternate;
}

@keyframes glow {
  from {
    color: #333;
  }
  to {
    color: #999;
  }
}

.ai-summary-tab-group {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  gap: 8px;
  margin-bottom: 12px;
  padding: 12px 12px 0;
}

.ai-summary-tab-group button {
  border: 1px solid #e8e8e8;
  border-radius: 4px;
  color: #666;
  background-color: rgba(0, 0, 0, 0.04);
  font-size: 14px;
  padding: 2px 12px;
  text-align: left;
}

.ai-summary-tab-group button.active {
  background-color: rgba(134, 92, 226, 0.1);
  border-color: rgba(134, 92, 226, 0.1);
  color: #865ce2;
}

@media screen and (max-width: 768px) {
}
