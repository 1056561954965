.price-bar {
  height: 6px;
  background-color: #d8d8d8;
  border-radius: 10px;
}

.price-bar.low {
  background-color: #54934a;
}

.price-bar.mid {
  background-color: #f0cb3d;
}

.price-bar.high {
  background-color: #d05050;
}

.state-section-body table {
  margin: 4px 0;
}

.no-plan-col {
  color: #999;
}

.state-section-body .rdt_TableRow > .rdt_TableCell:nth-child(1) > div {
  text-overflow: inherit;
  white-space: normal;
}
