.css-nxo287-MuiInputBase-input-MuiOutlinedInput-input {
    /* font-size: 0.5rem !important; */
    /* color: red; */
    /* width: 180px;
    height: 40px; */
    padding: 8px 14px !important;
}

.amplify-checkboxfield {
    padding-left: 2rem;
}
.hide {
    display: none;
}

.rank_datepicker{
    /* border: 1px #6691e7 solid;
    font-size: 1rem;
    border-radius: 5px;
    padding-left: 10px; */
}
.rdt_TableCol_Sortable{
    overflow: visible !important
}
.rdt_out>div{
    overflow: visible !important;
    overflow-y: visible !important;
    /* overflow-x: visible !important; */
}
.rdt_out{
    overflow-y: visible !important;
    overflow-x: overlay;
    min-height: 22rem;
}

@media (max-width :768px) {
    .rank_datepicker{
        display: none;
    }
    .rank_trend_market_table{
        overflow-x: overlay;
    }
    .rank_trend_market_table span{
        white-space: nowrap;
    }
}

@media (min-width:768px) {
    .mobile_rank_datepicker{
        display: none;
    }
}